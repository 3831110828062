import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appHeader: {
      image: null
    },
    shoppingCart: JSON.parse(localStorage.getItem('shopping-cart')) || [],
    shoppingCartOpen: false
  },
  mutations: {
    TOGGLE_SHOPPING_CART: (state, value) => {
      state.shoppingCartOpen = value
    },
    ADD_TO_SHOPPING_CART: (state, recipe) => {
      const search = state.shoppingCart.filter(item => item.slug === recipe.slug)
      if (!search.length) {
        state.shoppingCart.push(recipe)
      }
      localStorage.setItem('shopping-cart', JSON.stringify(state.shoppingCart))
    },
    UPDATE_SHOPPING_CART: (state, recipes) => {
      const keep = recipes.map(item => item.title)
      state.shoppingCart = state.shoppingCart.filter(recipe => {
        return keep.includes(recipe.title)
      })
      localStorage.setItem('shopping-cart', JSON.stringify(state.shoppingCart))
    },
    REMOVE_FROM_SHOPPING_CART: (state, recipe) => {
      state.shoppingCart = state.shoppingCart.filter(item => item.slug === recipe.slug)
      localStorage.setItem('shopping-cart', JSON.stringify(state.shoppingCart))
    }
  },
  actions: {
    toggleShoppingCart({ commit }, value) {
      commit('TOGGLE_SHOPPING_CART', value)
    },
    addToShoppingCart({ commit }, recipe) {
      commit('ADD_TO_SHOPPING_CART', recipe)
    },
    updateShoppingCart({ commit }, recipes) {
      commit('UPDATE_SHOPPING_CART', recipes)
    },
    removeFromShoppingCart({ commit }, recipe) {
      commit('REMOVE_FROM_SHOPPING_CART', recipe)
    }
  },
  getters: {
    addedToCart: state => slug => {
      return state.shoppingCart.find(recipe => recipe.slug === slug)
    },
    shoppingCartOpen: state => {
      return state.shoppingCartOpen
    },
    shoppingCartRecipes: state => {
      return state.shoppingCart.map(recipe => {
        const { id, slug, title } = recipe
        return { id, slug, title }
      })
    },
    shoppingCartRecipeCount: state => {
      return state.shoppingCart.length
    },
    shoppingCartIngredients: state => {
      const ingredients = {}
      state.shoppingCart.forEach(recipe => {
        if (recipe.ingredients.length) {
          recipe.ingredients.forEach(item => {
            const { ingredient, unit, amount } = item
            if (!ingredients[ingredient]) {
              ingredients[ingredient] = {}
            }
            if (!ingredients[ingredient][unit]) {
              ingredients[ingredient][unit] = 0
            }
            ingredients[ingredient][unit] += amount
          })
        }
      })
      return ingredients
    }
  },
  modules: {

  }
})
