import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        dark: false,
        light: true,
        themes: {
            light: {
                primary: '#5F43B2',
                secondary: '#3A3153',
                accent: '#B1AEBB',
                error: '#CB6869',
            }
        }
    }
}

export default new Vuetify(opts)