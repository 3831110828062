<template>
    <v-card
        tile
        hover
        class="recipe-card"
        :to="recipeUrl">
        <div v-if="false" class="recipe-card__chips">
            <v-chip small color="success" class="elevation-2">
                <strong>NEW</strong>
            </v-chip>
        </div>
        <v-img
            src="/app/dist/img/default.png"
            lazy-src="/app/dist/img/default.png"
            height="200"
            max-height="200">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"/>
                </v-row>
            </template>
        </v-img>
        <v-card-title class="font-weight-black">{{ recipe.title }}</v-card-title>
        <v-card-subtitle class="font-weight-medium pb-0">{{ recipe.subtitle }}</v-card-subtitle>
        <v-card-text class="pb-0">
            <v-row v-if="recipe.rating" align="center" class="mx-0 mb-1">
                <v-rating :value="4.5" color="black" dense half-increments readonly/>
                <div class="grey--text ms-1">4.5 (413)</div>
            </v-row>
            <v-chip-group multiple show-arrows class="mb-2">
                <v-chip small disabled class="me-2" v-for="tag, i in recipe.tags" :key="i">
                    <span class="font-weight-medium">{{ tag.title }}</span>
                </v-chip>
            </v-chip-group>
        </v-card-text>
        <ul class="list-inline mt-auto mb-5 px-5">
            <li class="d-flex me-4">
                <v-icon left color="primary">mdi-silverware-variant</v-icon>
                <span class="font-weight-medium">{{ recipe.servings }}</span>
            </li>
            <li class="d-flex me-4">
                <v-icon left color="primary">mdi-clock-outline</v-icon>
                <span class="font-weight-medium">{{ $duration(recipe.prepTime) }}</span>
            </li>
            <li class="d-flex me-4">
                <v-icon left color="primary">mdi-clock-fast</v-icon>
                <span class="font-weight-medium">{{ $duration(recipe.cookTime) }}</span>
            </li>
            <li class="d-flex">
                <v-icon left color="primary">mdi-silverware-clean</v-icon>
                <span class="font-weight-medium text-capitalize">{{ recipe.difficulty }}</span>
            </li>
        </ul>
        <v-divider/>
        <v-card-actions class="pa-4">
            <v-btn text :disabled="addedToCart" @click.prevent="addToShoppingCart">
                <v-icon left color="primary">mdi-cart-arrow-down</v-icon>
                <span>Add to Cart</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'RecipeCard',
    props: {
        recipe: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    computed: {
        recipeUrl () {
            if (this.recipe.slug) {
                return `/recipes/${this.recipe.slug}`
            }
            return '#'
        },
        addedToCart () {
            if (this.recipe.slug && this.$store.getters.addedToCart(this.recipe.slug)) {
                return true
            }
            return false
        }
    },
    methods: {
        addToShoppingCart () {
            this.$store.dispatch('addToShoppingCart', this.recipe)
        }
    }
}
</script>

<style lang="scss">
.v-chip {
    &--disabled {
        opacity: 1 !important;
        color: black !important;
    }
}

.v-image {
    cursor: pointer;
}

.v-rating {
    .v-icon:nth-child(1) {
        margin-left: -2px;
        padding-left: 0 !important;
    }
}
</style>