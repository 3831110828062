<template>
  <v-container class="homepage">
    <RecipeList/>
  </v-container>
</template>

<script>
import RecipeList from '@/components/RecipeList.vue'

export default {
  name: 'Home',
  components: {
    RecipeList
  }
}
</script>
