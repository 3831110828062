<template>
  <v-app id="app">
    <v-app-bar class="mb-5" elevation="0">
      <v-container>
        <v-row gutterless>
          <v-app-bar-title>
            <v-btn text tag="a" href="/">
              <v-icon left color="primary">mdi-chef-hat</v-icon>
              <span>rowley.recipes</span>
            </v-btn>
          </v-app-bar-title>
          <v-spacer></v-spacer>
          <v-badge bordered overlap color="primary">
            <template v-slot:badge>
              <strong>{{ shoppingCartCount }}</strong>
            </template>
            <v-btn text @click.prevent="openShoppingCart">
              <v-icon left color="primary">mdi-cart</v-icon>
              <span class="d-none d-md-block">Shopping Cart</span>
            </v-btn>
          </v-badge>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer class="mt-5">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="auto">
            <span class="d-flex justify-center justify-md-start text-caption text-uppercase text-md-left">&copy; {{ $moment().format('YYYY') }}&nbsp;<span class="font-weight-medium">Rowley.Recipes</span>; Made with <v-icon small class="mx-1" color="error">mdi-heart</v-icon>Grant R.</span>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn text tag="a" href="/admin" class="d-block d-md-inline-block">
              <v-icon left color="primary">mdi-knife</v-icon>
              <span>Chef Portal</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-navigation-drawer
      app
      absolute
      right
      temporary
      width="360px"
      v-model="shoppingCartOpen"
      @input="closeShoppingCart">
      <ShoppingCart/>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import ShoppingCart from '@/components/ShoppingCart.vue'

export default {
  name: 'App',
  components: {
    ShoppingCart
  },
  computed: {
    shoppingCartOpen: {
      get: function () {
        return this.$store.getters.shoppingCartOpen
      },
      set: function (v) {
        this.$store.dispatch('toggleShoppingCart', v)
      }
    },
    shoppingCartCount () {
      return this.$store.getters.shoppingCartRecipeCount || 0
    }
  },
  methods: {
    openShoppingCart () {
      this.$store.dispatch('toggleShoppingCart', true)
    },
    closeShoppingCart (open) {
      if (!open) {
        this.$store.dispatch('toggleShoppingCart', false)
      }
    }
  }
}
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>