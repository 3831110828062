<template>
    <div class="shopping-cart">
        <v-toolbar dark color="primary elevation-0">
            <v-toolbar-title class="d-flex align-center">
                <v-icon left>mdi-cart-variant</v-icon>
                <span>Shopping Cart</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeShoppingCart">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <p v-if="!recipesInCart.length" class="pa-5 text-center">Add some recipes to your cart</p>
        <v-expansion-panels v-else flat accordion mandatory>
            <v-expansion-panel>
                <v-expansion-panel-header class="px-4 text-h6" expand-icon="mdi-menu-down">
                    <v-icon left small class="flex-grow-0" color="black">mdi-pasta</v-icon>
                    <strong>Recipes</strong>
                    <span class="d-block flex-grow-0 ms-auto me-0 text-caption">({{ recipesInCartCount }})</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list shaped>
                        <v-list-item-group multiple v-model="recipesInCart">
                            <template v-for="recipe, i in recipesInCart">
                                <v-list-item :value="recipe" :key="i">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="recipe.title ? recipe.title : 'Undefined'"/>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active" color="primary"/>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="px-4 text-h6 align-center" expand-icon="mdi-menu-down">
                    <v-icon left small class="flex-grow-0" color="black">mdi-bowl-mix</v-icon>
                    <strong>Ingredients</strong>
                    <span class="d-block flex-grow-0 ms-auto me-0 text-caption">({{ ingredientsInCartCount }})</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list shaped>
                        <v-list-item-group multiple>
                            <v-list-item v-for="(value, ingredient) in ingredientsInCart" :key="ingredient">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong
                                                :class="{
                                                    'text-decoration-line-through': active
                                                }">
                                                {{ ingredient }}&nbsp;
                                            </strong>
                                            <template v-for="(amount, unit) in value">
                                                <span class="d-block text-caption" :key="unit">
                                                    {{ $fraction(amount) }}&nbsp;{{ unit }}
                                                </span>
                                            </template>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" color="primary"/>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog
            persistent
            width="300"
            transition="dialog-bottom-transition"
            v-model="emptyConfirm">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    block
                    x-large
                    color="error"
                    class="rounded-0 shopping-cart__empty"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="!recipesInCart.length">
                    <v-icon left>mdi-cart-remove</v-icon>
                    <span>Empty Shopping Cart</span>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar dark color="error" elevation="0">
                    <span class="d-block text-center flex-grow-1 font-weight-black text-h6">Empty your shopping cart?</span>
                </v-toolbar>
                <v-divider class="mb-4"/>
                <v-card-text class="text-center">
                    <p class="pa-0 ma-0">You are about to empty your shopping cart! Click <strong>&ldquo;Agree&rdquo;</strong> to confirm, or <strong>&ldquo;Disagree&rdquo;</strong> to continue without emptying your shopping cart.</p>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn
                        text
                        color="success"
                        class="ms-auto"
                        @click.prevent="clearShoppingCart">
                        <span>Agree</span>
                    </v-btn>
                    <v-btn text color="error" @click.prevent="emptyConfirm = false">
                        <span>Disagree</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ShoppingCart',
    data: () => ({
        busy: false,
        emptyConfirm: false,
        hideSelectedIngredients: false
    }),
    computed: {
        recipesInCart: {
            get () {
                return this.$store.getters.shoppingCartRecipes || []
            },
            set (recipes) {
                this.$store.dispatch('updateShoppingCart', recipes)
            }
        },
        recipesInCartCount () {
            return this.recipesInCart.length || 0
        },
        ingredientsInCart () {
            return this.$store.getters.shoppingCartIngredients || {}
        },
        ingredientsInCartCount () {
            return Object.keys(this.ingredientsInCart).length || 0
        }
    },
    methods: {
        closeShoppingCart () {
            this.$store.dispatch('toggleShoppingCart', false)
        },
        clearShoppingCart () {
            this.busy = true
            this.$store.dispatch('updateShoppingCart', [])
            setTimeout(() => {
                this.busy = false
                this.emptyConfirm = false
            }, 250)
        }
    }
}
</script>

<style lang="scss">
.v-expansion-panels {
    border-radius: 0;
}

.v-expansion-panel {
    border-top: 1px solid rgba(0,0,0,.12);

    &:first-child {
        border-top: 0;
    }

    &-content {
        &__wrap {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.v-list-item {
    margin-bottom: 8px;
}
</style>