import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import { formatQuantity } from 'format-quantity'

Vue.config.productionTip = false

Vue.prototype.$api = (query) => {
  return fetch(process.env.NODE_ENV === 'production'
    ? 'https://rowley.recipes/api'
    : 'https://rowley-recipes-craft.ddev.site/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 5X52ShQsMUrGYutls_TJSkn-c24fdG3I'
    },
    body: JSON.stringify({
      query: query
    })
  })
  .then(res => res.json())
  .then(res => {
    return res.data
  })
  .catch(err => {
    return err
  })
}

Vue.prototype.$moment = moment

Vue.prototype.$duration = (decimal) => {
  let value = decimal * 60
  let hours = Math.floor(value / 60)
  let minutes = value % 60
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
}

Vue.prototype.$fraction = (decimal) => {
  return formatQuantity(decimal)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
