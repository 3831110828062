<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <v-text-field
                    clearable
                    type="text"
                    label="Search our recipes..."
                    v-model="searchTerm"
                    clear-icon="mdi-close-circle"
                    prepend-inner-icon="mdi-magnify"
                    :disabled="busy"/>
            </v-col>
            <v-col cols="auto">
                <strong>
                    {{ filteredRecipes.length > 1000
                        ? `${Math.ceil(filteredRecipes.length / 1000)}k`
                        : filteredRecipes.length }}
                    &nbsp;recipe(s)
                </strong>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mb-6">
            <v-col cols="1" md="auto" class="text-center">
                <v-icon class="me-md-2" :color="searchTags.length ? 'primary' : 'default'">
                    {{ searchTags.length ? 'mdi-filter-variant-plus' : 'mdi-filter-variant' }}
                </v-icon>
            </v-col>
            <v-col cols="10" md="auto">
                <v-skeleton-loader v-if="busy" class="v-skeleton-loader--tags" type="chip@6"/>
                <v-chip-group
                    v-else
                    multiple
                    show-arrows
                    v-model="searchTags"
                    aria-label="Recipe Filters">
                    <v-chip
                        v-for="tag, i in tags"
                        :key="i"
                        filter
                        outlined
                        role="checkbox"
                        class="font-weight-medium">
                        <span>{{ tag.title }}</span>
                    </v-chip>
                </v-chip-group>
            </v-col>
            <v-col cols="1" md="auto" class="text-center ms-md-auto">
                <v-btn
                    icon
                    class="ms-md-2"
                    :disabled="!searchTags.length"
                    @click="clearTags">
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-item-group>
            <v-row justify="center">
                <v-col v-if="busy" cols="12" md="6" lg="4" xl="3">
                    <v-skeleton-loader
                        class="v-skeleton-loader--recipe"
                        type="image, article, chip@3, divider, actions"
                        elevation="2"/>
                </v-col>
                <v-col
                    v-else v-for="recipe, i in filteredRecipes"
                    :key="i"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3">
                    <RecipeCard :recipe="recipe"/>
                </v-col>
            </v-row>
        </v-item-group>
    </v-container>
</template>

<script>
import RecipeCard from '@/components/RecipeCard.vue'

export default {
    data: () => ({
        busy: false,
        tags: [],
        recipes: [],
        searchTerm: '',
        searchTags: [],
    }),
    components: {
        RecipeCard
    },
    computed: {
        filteredTags () {
            const tags = []
            this.searchTags.forEach(i => {
                tags.push(this.tags[i].title)
            })
            return tags
        },
        filteredRecipes () {
            const recipes = this.recipes.filter(recipe => {
                const tags = this.filteredTags.length ? this.filteredTags : false
                const query = this.searchTerm ? this.searchTerm.toLowerCase() : false
                if (tags || query) {
                    const title = recipe.title ? recipe.title.toLowerCase() : ''
                    const subtitle = recipe.subtitle ? recipe.subtitle.toLowerCase() : ''
                    const description = recipe.description ? recipe.description.toLowerCase() : ''
                    if (tags && recipe.tags.filter(tag => tags.includes(tag.title)).length) {
                        return recipe
                    }
                    if (query && (title.includes(query) || subtitle.includes(query) || description.includes(query))) {
                        return recipe
                    }
                } else {
                    return recipe
                }
            })
            return recipes
        }
    },
    methods: {
        async fetchTags () {
            const { tags } = await this.$api(`{
                tags(relatedToEntries:{section:"recipes", orderBy: "title DESC"}) {
                    id
                    title
                }
            }`)
            if (tags) {
                this.tags = tags
            }
        },
        async fetchRecipes () {
            this.busy = true
            const { entries } = await this.$api(`{
                entries (section: "recipes", orderBy: "dateCreated DESC") {
                    id
                    slug
                    title
                    author {
                        username
                    }
                    dateUpdated
                    dateCreated
                    ... on recipes_recipe_Entry {
                        subtitle
                        description
                        difficulty
                        cookTime
                        prepTime
                        servings
                        ingredients {
                            ingredient
                            amount
                            unit
                        }
                        instructions {
                            step
                            instruction
                        }
                        tags {
                            id
                            title
                        }
                        metaTitle
                        metaDescription
                    }
                }
            }`)
            if (entries) {
                this.recipes = entries
                setTimeout(() => {
                    this.busy = false
                }, 250)
            }
        },
        clearTags () {
            this.searchTags = []
        }
    },
    mounted () {
        this.fetchTags()
        this.fetchRecipes()
    }
}
</script>

<style lang="scss">
.v-skeleton-loader {
    $this: &;
    &--tags {
        #{$this}__chip {
            display: inline-block;
            margin-right: 16px;
        }
    }
    &--recipe {
        #{$this}__heading {
            margin-bottom: 8px !important;
        }
        #{$this}__paragraph {
            padding-top: 0 !important;
        }
        #{$this}__chip {
            display: inline-block;
            height: 24px;
            margin: 0 8px 0 0;
            &:nth-child(3) {
                margin-left: 16px;
                width: 118px;
            }
            &:nth-child(4) {
                width: 72px;
            }
        }
        #{$this}__divider {
            margin: 8px 16px 0;
        }
        #{$this}__actions {
            text-align: left;
        }
        #{$this}__button {
            min-width: 120px;
        }
    }
}
</style>